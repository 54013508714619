import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DateCalendarInput from "app/pages/SmartDP/Search/DateCalendarInput/DateCalendarInput";
import { fetchSDPAvailableDepartureDate } from "app/pages/SmartDP/smartDPActionCreators";
import { useCallback, useContext, useEffect } from "react";
import { addDays, format } from "date-fns";
import { useField, useFormikContext } from "formik";
import { SDP_ROTATION_DATE_FORMAT } from "app/constants";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import DateCalendarDrawerInput from "app/pages/SmartDP/Search/DateCalendarInput/DateCalendarDrawerInput";

const StopoverDateCalendarContainer = props => {
	const {
		updateFloatingPositionReference = () => {},
		handleDayChange = () => {},
		popperOffset = [],
		isHotelOnly = false,
	} = props;
	const [field] = useField(props);
	const [departureCityField] = useField("departureCity");
	const [destinationResortField] = useField("destinationResort");

	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const selectedDepartureDate = field.value?.departureDate;
	const selectedEndDate = field.value?.endDate;

	const { setFieldValue, setFieldTouched } = useFormikContext();

	const today = new Date();

	const stopoverCalendarMinDate = format(addDays(today, 2), SDP_ROTATION_DATE_FORMAT);
	const stopoverCalendarMaxDate = format(addDays(today, 340), SDP_ROTATION_DATE_FORMAT);
	const calendarMinDate = stopoverCalendarMinDate;
	let calendarMaxDate = stopoverCalendarMaxDate;

	if (selectedDepartureDate && !selectedEndDate) {
		calendarMaxDate = format(addDays(today, 350), SDP_ROTATION_DATE_FORMAT);
	}
	const isCalendarDisabled = isHotelOnly
		? !destinationResortField.value
		: !departureCityField.value && !destinationResortField.value;

	const clearTravelDatesFields = () => {
		setFieldValue("travelDates.departureDate", undefined);
		setFieldValue("travelDates.endDate", undefined);
		setFieldValue("duration", undefined);
		setFieldTouched("travelDates", false);
	};

	const checkDateAvailability = useCallback(date => {
		if (isCalendarDisabled) {
			return true;
		}
		if (selectedDepartureDate && !selectedEndDate) {
			const minDate = addDays(new Date(selectedDepartureDate), 3).getTime();
			const maxDate = addDays(new Date(), 350).getTime();
			const dateToCheck = new Date(date).getTime();

			// Enable the date if it is between the selected departure date + 3 days and today's date + 350 days
			if (dateToCheck >= minDate && dateToCheck <= maxDate) {
				return false;
			}
			return true;
		}

		return false;
	});
	useEffect(() => {
		if (selectedDepartureDate && selectedEndDate) {
			handleDayChange({
				selectedEndDate,
				selectedDepartureDate,
			});
		}
	}, [selectedDepartureDate, selectedEndDate]);

	const handleCalendarClose = useCallback(() => {
		if (selectedDepartureDate && !selectedEndDate) {
			clearTravelDatesFields();
		}
	}, [selectedDepartureDate, selectedEndDate]);

	return isMobile ? (
		<DateCalendarDrawerInput
			{...props}
			departureDateMin={calendarMinDate}
			departureDateMax={calendarMaxDate}
			checkDateAvailability={checkDateAvailability}
			onClose={handleCalendarClose}
			handleDayChange={handleDayChange}
			isCalendarDisabled={isCalendarDisabled}
			popperOffset={popperOffset}
			{...isHotelOnly && {
				displayCalendarIcon: false,
			}}
		/>
	) : (
		<DateCalendarInput
			{...props}
			departureDateMin={calendarMinDate}
			departureDateMax={calendarMaxDate}
			updateFloatingPositionReference={updateFloatingPositionReference}
			checkDateAvailability={checkDateAvailability}
			handleDayChange={handleDayChange}
			isCalendarDisabled={isCalendarDisabled}
			popperOffset={popperOffset}
			{...isHotelOnly && {
				displayCalendarIcon: false,
				displayFooterLabel: false,
			}}
		/>
	);
};

StopoverDateCalendarContainer.propTypes = {
	updateFloatingPositionReference: PropTypes.func,
	fetchSDPAvailableDepartureDate: PropTypes.func,
	handleDayChange: PropTypes.func,
	isHotelOnly: PropTypes.bool,
	popperOffset: PropTypes.array,
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchSDPAvailableDepartureDate,
		},
		dispatch
	);
};

export default connect(
	null,
	mapDispatchToProps
)(StopoverDateCalendarContainer);
